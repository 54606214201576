<template>
  <div>
    <BaseHeading size="small">
      Už len jeden krok a hotovo
    </BaseHeading>

    <div class="container">
      <InfoBox
        v-if="claimsCheckDisplay === 'INCREASE'"
        variant="danger"
        size="small"
      >
        Mrzí nás to, ale po overení vašej škodovej histórie vám bolo poistné navýšené.
      </InfoBox>

      <InfoBox
        v-if="claimsCheckDisplay === 'DECREASE'"
        variant="success"
        size="small"
        :icon="icons.claimsCheckDecrease"
      >
        Po overení škodovosti sme vám znížili cenu. Super!
      </InfoBox>

      <FormGroup
        label="Vyberte začiatok platnosti PZP"
        tooltipText="Označte deň, odkedy má PZP platiť. Poistenie sa dojednáva na dobu neurčitú s poistným obdobím jeden rok."
        :rules="['required']"
      >
        <InputCalendar
          v-model="productProps.startDate"
          :max="calendarMax"
        />
      </FormGroup>

      <FormGroup
        :label="`Máte od ${startDateFormatted} pre toto vozidlo platné PZP v inej poisťovni?`"
        :rules="['requiredOption']"
      >
        <InputOptions
          v-model="idd.duplicateInsurance"
          :options="optionsYN"
        />
      </FormGroup>
      <FormGroup
        :label="`Máte od ${startDateFormatted} pre toto vozidlo platné havarijné poistenie v inej poisťovni?`"
        :rules="['requiredOption']"
      >
        <InputOptions
          v-model="idd.collisionInsurance"
          :options="optionsYN"
        />
      </FormGroup>
      <FormGroup
        label="Ste vlastníkom vozidla?"
        tooltipText="Vlastníkom je ten, kto je zapísaný v dokladoch ako vlastník vozidla."
        :rules="['requiredOption']"
        id="vlastnik"
      >
        <InputOptions
          v-model="customer.isVehicleOwner"
          :options="optionsYN"
        />
      </FormGroup>
    </div>

    <div v-if="customer.isVehicleOwner === false">
      <BaseHeading
        ref="vehicleOwner"
        size="small"
      >
        Vlastník vozidla
      </BaseHeading>

      <div class="container">
        <InputParty
          v-model="$store.state.calculation.owner"
          :specialRules="['OWNER_YOUNGER_15_YEARS']"
          :unavailableNationalIdentificationNumbers="[customer.nationalIdentificationNumber]"
          :unavailableCompanyRegistrationNumbers="[customer.companyRegistrationNumber]"
        />
      </div>

      <BaseHeading size="small">
        <span v-if="owner.type === 'PERSON'">Adresa trvalého pobytu</span>
        <span v-else>Adresa sídla</span>
      </BaseHeading>

      <div class="container">
        <InputAddress
          v-model="owner.address"
        />
      </div>
    </div>

    <div v-if="showVehicleAdditional">
      <BaseHeading size="small">
        Vozidlo
      </BaseHeading>
      <div class="container">
        <FormGroup
          v-if="vehicleInfoNeeded.registrationNumber"
          label="Séria a číslo technického preukazu"
          :rules="['required']"
        >
          <InputText v-model="vehicle.registrationNumber" />
        </FormGroup>
        <FormGroup
          v-if="vehicleInfoNeeded.vin"
          label="VIN"
          :rules="['required', 'vinNumber']"
        >
          <InputText v-model="vehicle.vin" />
        </FormGroup>
        <FormGroup
          v-if="vehicleInfoNeeded.color"
          label="Farba (nepovinné)"
        >
          <InputText v-model="vehicle.color" />
        </FormGroup>
      </div>
    </div>

  </div>
</template>

<script>
import { lightFormat, addDays } from 'date-fns';
import scrollToElement from '@gds/util/scrollToElement';
import { faSmileWink } from '@fortawesome/pro-light-svg-icons';

import BaseHeading from '@gds/components/BaseHeading';
import InfoBox from '@gds/components/InfoBox';
import FormGroup from '@gds/components/FormGroup';
import InputOptions from '@gds/components/InputOptions';
import InputText from '@gds/components/InputText';
import InputCalendar from '@gds/components/InputCalendar';
import InputParty from '@gds/common/calculators/InputParty';
import InputAddress from '@gds/common/calculators/InputAddress';

import { mapGetters } from 'vuex';

export default {
  components: {
    BaseHeading,
    InfoBox,
    FormGroup,
    InputOptions,
    InputText,
    InputCalendar,
    InputParty,
    InputAddress,
  },
  data() {
    return {
      calendarMax: addDays(new Date(), 270),
      vehicleInfoNeeded: {
        registrationNumber: false,
        vin: false,
        color: false,
      },
      optionsYN: [
        { value: true, label: 'Áno' },
        { value: false, label: 'Nie' },
      ],
      icons: {
        claimsCheckDecrease: faSmileWink,
      },
    };
  },
  computed: {
    ...mapGetters([
      'productProps',
      'idd',
      'customer',
      'owner',
      'vehicle',
    ]),
    claimsCheckDisplay() {
      // COMPANY and SELFEMPLOYED are checked before, so no need to notify them at this point
      if (this.customer.type !== 'PERSON') {
        return null;
      }
      if (
        this.productProps.holderCausedDamage === false
        && this.productProps.claimsCheck === true
      ) {
        return 'INCREASE';
      }
      if (
        this.productProps.holderCausedDamage === true
        && this.productProps.claimsCheck === false
      ) {
        return 'DECREASE';
      }
      return null;
    },
    startDateFormatted() {
      const { startDate } = this.$store.state.calculation.productProps;
      return lightFormat(startDate, 'dd.MM.yyyy');
    },
    showVehicleAdditional() {
      return Object.values(this.vehicleInfoNeeded).some((v) => v);
    },
  },
  methods: {
    setVehicleInfoNeeded() {
      this.vehicleInfoNeeded.registrationNumber = !this.vehicle.registrationNumber;
      this.vehicleInfoNeeded.vin = !this.vehicle.vin;
      this.vehicleInfoNeeded.color = !this.vehicle.color;
    },
  },
  watch: {
    'customer.isVehicleOwner': function isVehicleOwnerUpdated(newValue) {
      if (newValue === false) {
        this.$nextTick(() => {
          scrollToElement({
            element: this.$refs.vehicleOwner.$el,
          });
        });
      }
    },
  },
  mounted() {
    this.$sendToCI();
    this.setVehicleInfoNeeded();
    this.$store.dispatch('update', {
      apollo: this.$apollo,
    });
  },
};
</script>
