<template>
  <div class="input-calendar">
    <InputText
      class="input-calendar-text"
      v-model="dateText"
    />
    <div class="input-calendar-picker">
      <header class="header">
        <button class="input-calendar-nav" @click="viewMonthPrevious()">
          <FontAwesomeIcon :icon="icons.prev" />
        </button>
        <h4 class="title">
          <InputSelect
            :value="cvMonth"
            :options="optionsMonths"
            class="input-calendar-select"
            @input="viewMonth($event)"
          />
          <InputSelect
            :value="cvYear"
            :options="optionsYears"
            class="input-calendar-select"
            @input="viewYear($event)"
          />
        </h4>
        <button class="input-calendar-nav" @click="viewMonthNext()">
          <FontAwesomeIcon :icon="icons.next" />
        </button>
      </header>
      <table class="input-calendar-table">
        <tr class="weekdays">
          <th v-for="(day, i) in cvDaysOfWeek" :key="i" class="dow">
            {{ day }}
          </th>
        </tr>
        <tr v-for="(week, i) in cvDates" :key="i">
          <td
            v-for="(date, i) in week" :key="i"
            @click="select(date)"
            :class="getDateClasses(date)"
          >
            {{ date.getDate() }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import './InputCalendar.scss';
import InputText from '@gds/components/InputText';
import InputSelect from '@gds/components/InputSelect';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';

import {
  format, parse, isEqual, isValid,
  startOfDay, startOfMonth, endOfMonth, addDays, addMonths,
  eachWeekOfInterval, eachDayOfInterval, eachYearOfInterval,
  setMonth, setYear,
} from 'date-fns';
import sk from 'date-fns/locale/sk';

const textFormat = 'd.M.y';

export default {
  name: 'InputCalendar',
  components: {
    InputText,
    InputSelect,
    FontAwesomeIcon,
  },
  props: {
    locale: {
      type: Object,
      default: () => sk,
    },
    min: {
      type: Date,
      default: () => startOfDay(new Date()),
    },
    max: {
      type: Date,
      default: () => startOfDay(addMonths(12, new Date())),
    },
    value: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      dateText: null,
      cvDate: this.value || startOfDay(new Date()),
      selectedDate: null,
      icons: {
        prev: faArrowLeft,
        next: faArrowRight,
      },
    };
  },
  computed: {
    cvWeeks() {
      return eachWeekOfInterval({
        start: startOfMonth(this.cvDate),
        end: endOfMonth(this.cvDate),
      }, {
        locale: this.locale,
      });
    },
    cvDates() {
      return this.cvWeeks.map((week) => eachDayOfInterval({
        start: week,
        end: addDays(week, 6),
      }));
    },
    cvDaysOfWeek() {
      return this.cvDates[0].map((day) => format(day, 'cccccc', { locale: this.locale }));
    },
    cvTitleFormatted() {
      return format(this.cvDate, 'LLLL y', { locale: this.locale });
    },
    cvMonth() {
      return this.cvDate.getMonth();
    },
    cvYear() {
      return this.cvDate.getFullYear();
    },
    optionsMonths() {
      return [
        { label: 'Január', value: 0 },
        { label: 'Február', value: 1 },
        { label: 'Marec', value: 2 },
        { label: 'Apríl', value: 3 },
        { label: 'Máj', value: 4 },
        { label: 'Jún', value: 5 },
        { label: 'Júl', value: 6 },
        { label: 'August', value: 7 },
        { label: 'September', value: 8 },
        { label: 'Október', value: 9 },
        { label: 'November', value: 10 },
        { label: 'December', value: 11 },
      ];
    },
    optionsYears() {
      const span = eachYearOfInterval({
        start: this.min,
        end: this.max,
      });
      return span.map((year) => ({
        label: year.getFullYear().toString(),
        value: year.getFullYear(),
      }));
    },
  },
  methods: {
    viewMonthPrevious() {
      this.cvDate = addMonths(this.cvDate, -1);
    },
    viewMonthNext() {
      this.cvDate = addMonths(this.cvDate, 1);
    },
    viewMonth(month) {
      this.cvDate = setMonth(this.cvDate, month);
    },
    viewYear(year) {
      this.cvDate = setYear(this.cvDate, year);
    },
    select(date) {
      if (
        isValid(date)
        && !isEqual(date, this.selectedDate)
        && !this.isDisabled(date)
      ) {
        this.selectedDate = date;

        if (!isEqual(startOfMonth(this.selectedDate), startOfMonth(this.cvDate))) {
          this.cvDate = this.selectedDate;
        }
      }
    },
    isDisabled(date) {
      return date < this.min || date > this.max;
    },
    getDateClasses(date) {
      return {
        day: true,
        active: isEqual(date, this.selectedDate),
        disabled: this.isDisabled(date),
      };
    },
  },
  watch: {
    selectedDate(newValue) {
      this.dateText = format(newValue, textFormat);
      this.$emit('input', newValue);
      this.$emit('change', newValue);
    },
    dateText(newValue) {
      const parsed = parse(newValue, textFormat, this.selectedDate);
      this.select(parsed);
    },
  },
  created() {
    this.selectedDate = this.value;
  },
};
</script>
